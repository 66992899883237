<template>
   <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
      <div class="card-body p-12">
         <div class="row align-items-center">
            <div class="col">
               <div class="row align-items-center">
                  <div class="col-12 px-0">
                     <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ editado.nome }}</h1>
                     <p class="font-10 mb-1 limitador-1 text-secondary">
                        <span><i class="far fa-barcode font-9 me-1"></i>{{ editado.codigos }}</span>
                     </p>
                  </div>
                  <div class="col-12 px-0">
                     <p class="limitador-1 font-12 mb-0">
                        <i class="far fa-box color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Quantidade:</strong>
                        <span> {{ parseFloat(editado.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits: 3}) }}</span>
                     </p>
                  </div>
               </div>
            </div>
            <div class="w-max-content font-16 text-center">
               <div class="btn-icone text-red">
                  <span @click="remover"><i class="far fa-trash me-0"></i></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Editado',
   props: ['editado', 'index'],
   methods: {
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>